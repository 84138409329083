<script setup lang="ts">
import { onMounted, ref } from 'vue'

const bc = new BroadcastChannel('test_channel')
bc.onmessage = (ev) => {
  console.log('Message received:', ev.data)
  if (ev.data === 'Die, duplicate!') {
    message.value = 'Øv jeg er duplet'
    bc.close()
    original.value = false
  } else if (ev.data === 'Anybody out there?') {
    bc.postMessage('Die, duplicate!')
    original.value = true
  }
}

bc.postMessage('Anybody out there?')

const message = ref('Er orignalen')
const original = ref(true)

const patient_id = ref('123456')
const course_id = ref('654321')
const user_id = ref('ext-medware-jg')
const manatee_set_at = ref('')

const app_patient_id = ref('123456')
const app_course_id = ref('654321')
const app_user_id = ref('ext-medware-jg')
const app_set_at = ref('')

onMounted(() => {
  console.log('App mounted')
})
</script>

<template>
  <h1>{{ message }}</h1>
  <div v-if="original">
    <h3>Manatee input felter</h3>
    <form>
      <p>Patient ID (manatee_patient_id)</p>
      <input id="manatee_patient_id" v-model="patient_id" placeholder="patient id..." />
      <p>Course ID (manatee_course_id)</p>
      <input id="manatee_course_id" v-model="course_id" placeholder="course id..." />
      <p>User ID (manatee_user_id)</p>
      <input id="manatee_user_id" v-model="user_id" placeholder="user id..." />
      <p>Manatee kontekst opdateret (manatee_set_at)</p>
      <input id="manatee_set_at" v-model="manatee_set_at" placeholder="values set at..." />
    </form>
    <h3>Applikation input felter</h3>
    <form>
      <p>Patient ID (app_patient_id)</p>
      <input id="app_patient_id" v-model="patient_id" placeholder="patient id..." />
      <p>Course ID (app_course_id)</p>
      <input id="app_course_id" v-model="course_id" placeholder="course id..." />
      <p>User ID (app_user_id)</p>
      <input id="app_user_id" v-model="user_id" placeholder="user id..." />
      <p>app kontekst opdateret (app_set_at)</p>
      <input id="app_set_at" v-model="app_set_at" placeholder="values set at..." />
    </form>
  </div>
  <div v-else>
    <h3></h3>
  </div>
</template>
